var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-card",
        { staticClass: "cardClassDetailForm", attrs: { title: "LBLDETAIL" } },
        [
          _c(
            "template",
            { slot: "card-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: { label: "LBLSAVE", icon: "save" },
                        on: { btnClicked: _vm.saveResult },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    readonly: true,
                    label: "심사일",
                    name: "date",
                  },
                  model: {
                    value: _vm.result.date,
                    callback: function ($$v) {
                      _vm.$set(_vm.result, "date", $$v)
                    },
                    expression: "result.date",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    readonly: true,
                    label: "피심사팀",
                    name: "deptName",
                  },
                  model: {
                    value: _vm.result.deptName,
                    callback: function ($$v) {
                      _vm.$set(_vm.result, "deptName", $$v)
                    },
                    expression: "result.deptName",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8",
              },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    readonly: true,
                    label: "심사내용",
                    name: "col1",
                  },
                  model: {
                    value: _vm.result.col1,
                    callback: function ($$v) {
                      _vm.$set(_vm.result, "col1", $$v)
                    },
                    expression: "result.col1",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4",
              },
              [
                _c("c-textarea", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    rows: 5,
                    label: "심사 결과 요약",
                    name: "auditResult",
                  },
                  model: {
                    value: _vm.result.auditResult,
                    callback: function ($$v) {
                      _vm.$set(_vm.result, "auditResult", $$v)
                    },
                    expression: "result.auditResult",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4",
              },
              [
                _c("c-textarea", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    rows: 5,
                    label: "심사 지적사항",
                    name: "auditErrorList",
                  },
                  model: {
                    value: _vm.result.auditErrorList,
                    callback: function ($$v) {
                      _vm.$set(_vm.result, "auditErrorList", $$v)
                    },
                    expression: "result.auditErrorList",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4",
              },
              [
                _c("c-textarea", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    rows: 5,
                    label: "기타사항",
                    name: "etc",
                  },
                  model: {
                    value: _vm.result.etc,
                    callback: function ($$v) {
                      _vm.$set(_vm.result, "etc", $$v)
                    },
                    expression: "result.etc",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "실시결과 및 결론 목록",
            columns: _vm.grid.columns,
            data: _vm.result.list,
            gridHeight: _vm.grid.height,
            editable: _vm.editable && !_vm.disabled,
            hideBottom: true,
            isExcelDown: false,
            filtering: false,
            isFullScreen: false,
            columnSetting: false,
            selection: "multiple",
            rowKey: "id",
          },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: { label: "LBLEXCEPT", icon: "remove" },
                        on: { btnClicked: _vm.deleteResult },
                      })
                    : _vm._e(),
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: { label: "LBLADD", icon: "add" },
                        on: { btnClicked: _vm.addResult },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }